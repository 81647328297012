import pegawaiFormModel from './pegawaiFormModel';

const {
  formField: {
    kode,
    idAnggota,
    nama,
    namaTanpaGelar,
    jenisKelamin,
    tempatLahir,
    tglLahir,
    statusPerkawinan,
    agama,
    pendidikan,
    ukuranBaju,
    kotaKab,
    kecamatan,
    alamat,
    useDomisili,
    noRekening,
    noHp,
    noDarurat,
    nik,
    npwp,
    bpjs,
    email,
    tglMasuk,
    tglKeluar,
    kantor,
    jabatan,
    integritas,
    statusPegawai,
    tglStatus,
    tglMulaiKontrak,
    tglAkhirKontrak,
    kontrakMagangKe,
    kontrakPkwtKe,
    notes,
    profilUrl,
    ttdUrl
  }
} = pegawaiFormModel;

export default {
  [kode.name]: '',
  [idAnggota.name]: '',
  [nama.name]: '',
  [namaTanpaGelar.name]: '',
  [jenisKelamin.name]: '',
  [tempatLahir.name]: '',
  [tglLahir.name]: '',
  [statusPerkawinan.name]: '',
  [agama.name]: '',
  [pendidikan.name]: '',
  [ukuranBaju.name]: '',
  [kotaKab.name]: '',
  [kecamatan.name]: '',
  [alamat.name]: '',
  [useDomisili.name]: false,
  [noRekening.name]: '',
  [noHp.name]: '',
  [noDarurat.name]: '',
  [nik.name]: '',
  [npwp.name]: '',
  [bpjs.name]: '',
  [email.name]: '',
  [tglMasuk.name]: '',
  [tglKeluar.name]: '',
  [kantor.name]: '',
  [jabatan.name]: '',
  [integritas.name]: '',
  [statusPegawai.name]: '',
  [tglStatus.name]: '',
  [tglMulaiKontrak.name]: '',
  [tglAkhirKontrak.name]: '',
  [kontrakMagangKe.name]: '',
  [kontrakPkwtKe.name]: '',
  [notes.name]: '',
  [profilUrl.name]: '',
  [ttdUrl.name]: ''
};
