import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Stack,
  Card,
  Container,
  IconButton,
  Avatar,
  Typography,
  ButtonGroup,
  Button,
  Grid,
  Modal,
  Box
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id } from "date-fns/locale";
import { format, parseISO } from 'date-fns';
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import Iconify from '../../../components/iconify';
import AddPegawaiPage from './AddPegawaiPage';
import ProfilPegawaiPage from './ProfilPegawaiPage';
import AkunPegawaiPage from './AkunPegawaiPage';
import KepegawaianPegawaiPage from './KepegawaianPegawaiPage';
import FotoPegawaiPage from './FotoPegawaiPage';
import ViewPdfPegawaiPage from './DetailPegawaiPage';
import DokPegawaiPage from './dokumen/DokPegawaiPage';
import KinerjaPegawaiPage from './KinerjaPegawaiPage';
// import RumpunPegawaiPage from './RumpunPegawaiPage';
import PegawaiPopover from './PegawaiPopover';
import { getCurrentDate, customDate, dateDiff } from "../../../utils/formatDate";
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function PegawaiPage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedId, setSelectedId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "nama",
      label: "Nama",
      options: {
      filter: false,
      sort: true,
      customBodyRender: (nama, meta) => {
        const profilUrlId = meta.rowData[15];
        const profilUrl = profilUrlId ? `https://drive.google.com/thumbnail?id=${profilUrlId}` : '';
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={profilUrl} src={profilUrl} imgProps={{ referrerPolicy: "no-referrer" }} />
            <Typography variant="subtitle2" noWrap>
              {nama}
            </Typography>
          </Stack>
        )
      }
      }
    },
    {
      name: "namaTanpaGelar",
      label: "Nama Tanpa Gelar",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      }
    },
    {
     name: "kodePegawai",
     label: "Kode Pegawai",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "jenisKelamin",
     label: "Jenis Kelamin",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
     name: "kantor",
     label: "Kantor",
     options: {
      filter: true,
      sort: false,
     }
    },
    {
      name: "statusPegawai",
      label: "Status Pegawai",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
       filter: true,
       sort: false,
      }
     },
    {
      name: "tglMasuk",
      label: "Tgl Masuk",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => Object.prototype.toString.call(value) ? format(parseISO(value), 'dd/MM/yyyy') : format(new Date(value), 'dd/MM/yyyy'),
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return [`Masuk Dari: ${startDate}`, `Sampai Dengan: ${enDate}`];
            } if (v[0]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');

              return `Masuk Dari: ${startDate}`;
            } if (v[1]) {
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return `Sampai Dengan: ${enDate}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          names: [],
          logic(date, filters) {
            const formated = new Date(customDate(date));

            if (filters[0] && filters[1]) {
              return !(formated >= filters[0] && formated <= filters[1]);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Masuk Dari"
                      value={filterList[index][0] || null}
                      onChange={date => {
                        filterList[index][0] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Sampai Dengan"
                      value={filterList[index][1] || null}
                      onChange={date => {
                        filterList[index][1] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </>
          ),
        },
      }
    },
    {
      name: "tglMasuk",
      label: "Lama Kerja",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => getDateDiff(format(parseISO(value), 'yyyy-MM-dd'), getCurrentDate('-'))
      }
    },
    {
      name: "tglStatus",
      label: "Tgl PKWTT",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "tglMulaiKontrak",
      label: "Tgl Mulai Kontrak",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "tglAkhirKontrak",
      label: "Tgl Akhir Kontrak",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "kontrakMagangKe",
      label: "Magang Ke",
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: "kontrakPkwtKe",
      label: "PKWT Ke",
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: "profilUrlId",
      label: "Google Drive Photo ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false
      }
    },
     // Hidden Columns
    {
      name: "tempatLahir",
      label: "Tempat Lahir",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "tglLahir",
      label: "Tgl Lahir",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "statusPerkawinan",
      label: "Status Perkawinan",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "pendidikan",
      label: "Pendidikan",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "agama",
      label: "Agama",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "ukuranBaju",
      label: "Ukuran Baju",
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: "kecamatan",
      label: "Kecamatan",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "kotaKabupaten",
      label: "Kota Kabupaten",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "alamat",
      label: "Alamat",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => value ? value.trim() : ''
      }
    },
    {
      name: "kecamatanDomisili",
      label: "Kecamatan Domisili",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "kotaKabupatenDomisili",
      label: "Kota Kabupaten Domisili",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "alamatDomisili",
      label: "Alamat Domisili",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => value ? value.trim() : ''
      }
    },
    {
      name: "noRekening",
      label: "No. Rekening",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "noHp",
      label: "No. Hp",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "noDarurat",
      label: "No. Darurat",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "nik",
      label: "NIK",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "npwp",
      label: "NPWP",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "bpjs",
      label: "BPJS",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "integritas",
      label: "Integritas",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => value ? 'Ya' : 'Tidak'
      }
    }, // End Hidden Columns
    {
      name: "_id",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value) => (
        <IconButton size="small" color="inherit" onClick={(event) => handleOpenMenu(event, value)}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `pegawai-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleUpdateTable = (newData) => {
    const index = data.findIndex(item => item._id === newData._id);
    
    if (index !== -1) {
      data[index] = { ...data[index], ...newData };

      setData(data);
    } else {
      // console.log(newData);
      setData(item => [...item, newData]);
    }
  }

  const RenderFormContent = () => {
    switch (modalContent) {
      case 0:
        return <AddPegawaiPage
                handleUpdateTable={handleUpdateTable}
              />;
      case 1:
        return <ProfilPegawaiPage
                  id={selectedId}
                  handleCloseModal={handleCloseModal}
                  handleUpdateTable={handleUpdateTable}
                />;
      case 2:
        return <AkunPegawaiPage
                  id={selectedId}
                  handleCloseModal={handleCloseModal}
                  handleUpdateTable={handleUpdateTable}
                />;
      case 3:
        return <KepegawaianPegawaiPage
                  id={selectedId}
                  handleCloseModal={handleCloseModal}
                  handleUpdateTable={handleUpdateTable}
                />;
      case 4:
        return <FotoPegawaiPage
                  id={selectedId}
                  handleCloseModal={handleCloseModal}
                  handleUpdateTable={handleUpdateTable}
                />;
      case 5:
        return <ViewPdfPegawaiPage
                  id={selectedId}
                />;
      case 6:
        return <DokPegawaiPage
                  idPegawai={selectedId}
                  handleCloseModal={handleCloseModal}
                  handleUpdateTable={handleUpdateTable}
                />;
      case 7:
        return <KinerjaPegawaiPage
                  idPegawai={selectedId}
                  handleCloseModal={handleCloseModal}
                  handleUpdateTable={handleUpdateTable}
                />;
      // case 8:
      //   return <RumpunPegawaiPage
      //             idPegawai={selectedId}
      //             handleCloseModal={handleCloseModal}
      //             handleUpdateTable={handleUpdateTable}
      //           />;
      default:
        return <div>Not display anything</div>;
    }
  }

  const getDateDiff = (startDate, endDate) => {
    const { years, months, days } = dateDiff(startDate, endDate);

    let result = '';
    if (years > 0) {
        result += `${years} Tahun `;
    }
    if (months > 0) {
        result += `${months} Bulan `;
    }
    if (days > 0) {
        result += `${days} Hari `;
    }

    return result.trim(); // Remove trailing space
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('Pegawai')
    .then((response) => {
      // console.log(response?.data);
      setData(response?.data)
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> List Pegawai | Sip App </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 2 }}>
          <ButtonGroup variant="contained" color="primary">
            <Button
              onClick={() => navigate("../kepegawaian/pegawai/import")} 
              startIcon={<Iconify icon="eva:upload-fill" />}
            >
              Import
            </Button>
            <Button
              onClick={() => handleOpenModal(0)} 
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Tambah
            </Button>
          </ButtonGroup>
        </Stack>
        <Card>
          <MUIDataTable
            title="List Pegawai"
            data={data}
            columns={columns}
            options={options}
            handleOpenMenu={handleOpenMenu}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <PegawaiPopover
        open={Boolean(open)}
        anchorEl={open}
        navigate={navigate}
        selectedId={selectedId}
        handleCloseMenu={handleCloseMenu}
        handleOpenModal={handleOpenModal}
      />

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalOpen}
        onClose={handleCloseModal}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <RenderFormContent />
        </>
      </Modal>
    </>
  );
}
