import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import ProfilForm from './forms/ProfilForm';
import AkunForm from './forms/AkunForm';
import KepegawaianForm from './forms/KepegawaianForm';
import FotoForm from './forms/FotoForm';

import validationSchema from './form-model/validationSchema';
import profilFormModel from './form-model/pegawaiFormModel';
import formInitialValues from './form-model/formInitialValues';

import useStyles from './styles';

const steps = ['Profil', 'Akun', 'Kepegawaian', 'Foto'];
const { formId, formField } = profilFormModel;

export default function PegawaiForm({data, dropDown, handleUpdateTable}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const notificationCtx = useContext(NotificationContext);
  const [id, setId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [result, setResult] = useState([]);
  const currentValidationSchema = validationSchema[activeStep];
  // const isLastStep = activeStep === steps.length - 1;
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [dropdownData, setDropdownData] = useState({
    provinsis: [],
    kotaKabs: [],
    kecamatans: [],
    kotaKabDomis: [],
    kecamatanDomis: []
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedProvinsi, setSelectedProvinsi] = useState();
  const [selectedProvinsiDomi, setSelectedProvinsiDomi] = useState();

  useEffect(() => {
    const fetchDropdownData = async () => {
      setPageLoading(true);
      try {
        const provinsis = await axiosPrivate.get('/region/provinsi');
        setDropdownData(prev => ({ ...prev, provinsis: provinsis.data }));
      } catch (error) {
        console.error('Failed to load initial dropdown data:', error);
      } finally {
        setPageLoading(false);
      }
    };

    fetchDropdownData();
  }, []);

  const _submitForm = async (values, actions) => {
    const {
      kode,
      idAnggota,
      nama,
      namaTanpaGelar,
      jenisKelamin,
      tempatLahir,
      tglLahir,
      statusPerkawinan,
      pendidikan,
      agama,
      ukuranBaju,
      provinsi,
      kecamatan,
      kotaKab,
      alamat,
      useDomisili,
      provinsiDomisili,
      kecamatanDomisili,
      kotaKabDomisili,
      alamatDomisili,
      noRekening,
      noHp,
      noDarurat,
      nik,
      npwp,
      bpjs,
      email,
      tglMasuk,
      tglKeluar,
      kantor,
      jabatan,
      integritas,
      statusPegawai,
      tglStatus,
      tglMulaiKontrak,
      tglAkhirKontrak,
      kontrakMagangKe,
      kontrakPkwtKe,
      notes
    } = values;

    // console.log(values);
  
    const params = {
      kodePegawai: kode,
      idAnggota,
      nama,
      namaTanpaGelar,
      jenisKelamin,
      tempatLahir,
      tglLahir: moment(tglLahir).format('YYYY-MM-DD'),
      statusPerkawinan,
      pendidikan,
      ukuranBaju,
      agama,
      provinsi,
      kecamatan,
      kotaKabupaten: kotaKab,
      alamat,
      domisili: useDomisili,
      provinsiDomisili,
      kecamatanDomisili,
      kotaKabupatenDomisili: kotaKabDomisili,
      alamatDomisili,
      noRekening,
      noHp,
      noDarurat,
      nik,
      npwp,
      bpjs,
      email,
      tglMasuk: moment(tglMasuk).format('YYYY-MM-DD'),
      kantor,
      jabatan,
      integritas,
      statusPegawai,
      tglStatus: tglStatus ? moment(tglStatus).format('YYYY-MM-DD') : undefined,
      tglMulaiKontrak: tglMulaiKontrak ? moment(tglMulaiKontrak).format('YYYY-MM-DD') : undefined,
      tglAkhirKontrak: tglAkhirKontrak ? moment(tglAkhirKontrak).format('YYYY-MM-DD') : undefined,
      kontrakMagangKe,
      kontrakPkwtKe,
      notes,
      dibuatOleh: user?._id,
      tglBuat: moment().format('YYYY-MM-DDTHH:mm:ss'),
    };

    // Check if tglKeluar exists and is not an empty string, then add it to params
    if (tglKeluar) {
      params.tglKeluar = moment(tglKeluar).format('YYYY-MM-DD');
    }

    // console.log(JSON.stringify(params));
  
    try {
      const response = await axiosPrivate.post(`/pegawai`, params);
      const { data } = response;
      const { result } = data;

      setBtnDisabled(true);
      setResult(result);
      handleMessage(data);
      setId(result._id);
      setActiveStep(activeStep + 1);
    } catch (error) {
      console.log(error);
      handleMessage(error);
    } finally {
      actions.setSubmitting(false);
      setBtnDisabled(false);
    }
  };

  const fetchData = async (endpoint, key, value) => {
    try {
      const url = value ? `/region/${endpoint}/${value}` : `/region/${endpoint}`;
      const { data } = await axiosPrivate.get(url);
      setDropdownData(prev => ({ ...prev, [key]: data }));
      return data;
    } catch (error) {
      console.error(`Failed to fetch data from ${endpoint}:`, error);
      return [];
    }
  };

  const handleSetKotaKabs = async (provinceKey) => {
    try {
      const kotaKabs = await fetchData('kabupaten-kota', 'kotaKabs', provinceKey);
      setDropdownData(prev => ({
        ...prev,
        kotaKabs,
        kecamatans: [] // Clear kecamatans when province changes
      }));
      setSelectedProvinsi(provinceKey);
    } catch (error) {
      console.error('Error updating kotaKabs:', error);
    }
  };

  const handleSetKecamatans = async (cityKey) => {
    try {
      const kecamatans = await fetchData('kecamatan', 'kecamatans', `${selectedProvinsi}/${cityKey}`);
      setDropdownData(prev => ({
        ...prev,
        kecamatans
      }));
    } catch (error) {
      console.error('Error updating kecamatans:', error);
    }
  };
  
  const handleSetKotaKabDomis = async (provinceKey) => {
    try {
      const kotaKabDomis = await fetchData('kabupaten-kota', 'kotaKabDomis', provinceKey);
      setDropdownData(prev => ({
        ...prev,
        kotaKabDomis,
        kecamatanDomis: [] // Clear kecamatanDomis when domisili province changes
      }));
      setSelectedProvinsiDomi(provinceKey);
    } catch (error) {
      console.error('Error updating kotaKabDomis:', error);
    }
  };
  
  const handleSetKecamatanDomis = async (cityKey) => {
    try {
      const kecamatanDomis = await fetchData('kecamatan', 'kecamatanDomis', `${selectedProvinsiDomi}/${cityKey}`);
      setDropdownData(prev => ({
        ...prev,
        kecamatanDomis
      }));
    } catch (error) {
      console.error('Error updating kecamatanDomis:', error);
    }
  };

  const uploadImage = async (values, actions) => {
    const { kode, profilUrl, ttdUrl } = values;
  
    const params = {
      profilUrl,
      ttdUrl
    };
  
    // console.log(JSON.stringify({id, kodePegawai: kode, newData: params}));
  
    setBtnDisabled(true);
    try {
      const response = await axiosPrivate.post(`/pegawai/file/${id}`, { id, kodePegawai: kode, newData: params });
      const { data } = response;
      
      handleMessage(data);
      handleUpdateTable(result);
    } catch (error) {
      console.log(error);
      handleMessage(error);
    } finally {
      actions.setSubmitting(false);
      setBtnDisabled(false);
    }
  };
  

  const handleMessage = (params) => {
    if(params?.success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.response
      })
    }
  }

  const _handleSubmit = (values, actions) => {
    switch (activeStep) {
      case 2:
        _submitForm(values, actions);
        break;
      case 3:
        uploadImage(values, actions);
        break;
      default:
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
    }
  }

  const _handleBack = () => {
    setActiveStep(activeStep - 1);
  }

  const _renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <ProfilForm
                formField={formField}
                provinsis={dropdownData.provinsis}
                kotaKabs={dropdownData.kotaKabs}
                kecamatans={dropdownData.kecamatans}
                kotaKabDomis={dropdownData.kotaKabDomis}
                kecamatanDomis={dropdownData.kecamatanDomis}
                handleSetKotaKabs={handleSetKotaKabs}
                handleSetKecamatans={handleSetKecamatans}
                handleSetKotaKabDomis={handleSetKotaKabDomis}
                handleSetKecamatanDomis={handleSetKecamatanDomis}
              />;
      case 1:
        return <AkunForm formField={formField} />;
      case 2:
        return <KepegawaianForm formField={formField} dropDown={dropDown} />;
      case 3:
        return <FotoForm formField={formField} />;
      default:
        return <div>Not Found</div>;
    }
  }

  const _renderButtonStep = (step) => {
    switch (step) {
      case 2:
        return 'Simpan'
      case 3:
        return 'Selesai'
      default:
        return 'Selanjutnya'
    }
  }

  useEffect(() => {
    if(data) {
      setInitialValues({
        id: data._id,
        kode: data.kodePegawai,
        idPegawai: data.idPegawai,
        idAnggota: data.idAnggota,
        nama: data.nama,
        namaTanpaGelar: data.namaTanpaGelar,
        jenisKelamin: data.jenisKelamin,
        tempatLahir: data.tempatLahir,
        tglLahir: data.tglLahir,
        statusPerkawinan: data.statusPerkawinan,
        pendidikan: data.pendidikan,
        agama: data.agama,
        kotaKab: data.kotaKabupaten,
        provinsi: data.provinsi,
        kecamatan: data.kecamatan,
        alamat: data.alamat,
        useDomisili: data.domisili,
        provinsiDomisili: data.provinsiDomisili,
        kotaKabDomisili: data.kotaKabupatenDomisili,
        kecamatanDomisili: data.kecamatanDomisili,
        noRekening: data.noRekening,
        noHp: data.noHp,
        noDarurat: data.noDarurat,
        nik: data.nik,
        npwp: data.npwp,
        bpjs: data.bpjs,
        email: data.email,
        tglMasuk: data.tglMasuk,
        tglKeluar: data.tglKeluar,
        kantor: data.kantor,
        jabatan: data.jabatan,
        integritas: data.integritas,
        statusPegawai: data.statusPegawai,
        tglStatus: data.tglStatus,
        tglMulaiKontrak: data.tglMulaiKontrak,
        tglAkhirKontrak: data.tglAkhirKontrak,
        kontrakMagangKe: data?.kontrakMagangKe,
        kontrakPkwtKe: data?.kontrakPkwtKe,
        notes: data?.notes,
        profilUrl: data.profilUrlId ? `https://drive.google.com/uc?export=view&id=${data.profilUrlId}` : '',
        ttdUrl: data.ttdUrlId ? `https://drive.google.com/uc?export=view&id=${data.ttdUrlId}` : ''
      });
    }
  }, [data]);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {(activeStep !== 0 && activeStep !== 3) && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Kembali
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <LoadingButton
                      disabled={btnDisabled}
                      type="submit"
                      loading={isSubmitting}
                      variant="contained"
                      className={classes.button}
                    >
                      {_renderButtonStep(activeStep)}
                    </LoadingButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}
