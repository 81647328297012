import { useState, useEffect, useRef, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { GoogleMap, LoadScript, Marker, OverlayView, DirectionsRenderer } from "@react-google-maps/api";
import {
  Button,
  Container,
  Stack,
  Typography,
  TextField,
  Autocomplete
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id } from "date-fns/locale";
import { format } from "date-fns";
import { formatInTimeZone } from 'date-fns-tz';
import { axiosPrivate } from "../../../common/axiosPrivate";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const defaultCenter = { lat: -6.149770617808631, lng: 106.18218026749454 }; // Kantor Pusat Rabani 

const CustomLabel = ({ koordinat, label }) => (
  <OverlayView
    position={koordinat}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  >
    <div
      style={{
        position: "absolute",
        background: "#fff",
        padding: "4px 8px",
        borderRadius: "4px",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
        whiteSpace: "nowrap",
        fontSize: "12px",
        fontWeight: "bold",
        transform: "translate(-110%, -50%)", // Moves the label to the left of the marker
      }}
    >
      {label}
    </div>
  </OverlayView>
);

export default function TrackingPenagihanPage() {
  const [date, setDate] = useState(null); // Single date filter
  const [petugas, setPetugas] = useState(""); // Selected petugas ID
  const [petugasList, setPetugasList] = useState([]); // Petugas options
  const [trackingData, setTrackingData] = useState([]); // Array of objects with { kooridnat, tanggal, ... }
  const [loading, setLoading] = useState(false);
  const [directions, setDirections] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [legsInfo, setLegsInfo] = useState([]);
  const mapRef = useRef(null);

  // Fetch petugas list on mount
  useEffect(() => {
    const fetchPetugasList = async () => {
      try {
        const response = await axiosPrivate.get("/pegawai/dropdown/list/all");
        setPetugasList(response.data);
      } catch (error) {
        console.error("Error fetching petugas list:", error);
      }
    };
    fetchPetugasList();
  }, []);

  // Calculate route using DirectionsService
  const calculateRoute = useCallback((data) => {
    if (data.length < 2) return;
  const origin = data[0].koordinat;
  const destination = data[data.length - 1].koordinat;
  const waypoints = data.slice(1, data.length - 1).map((item) => ({
    location: item.koordinat,
    stopover: true,
  }));

  const directionsService = new window.google.maps.DirectionsService();
  directionsService.route(
    {
      origin,
      destination,
      waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
    },
    (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setDirections(result);
        const route = result.routes[0];
        let totalDist = 0;
        const legs = route.legs.map((leg, index) => {
          totalDist += leg.distance.value;
          // For segment A-B, use tracking item A; for segment C-D, if tracking item C has dtd true, extra is 600.
          const extraSeconds = data[index].dtd ? 300 : 1200;
          const adjustedDurationValue = leg.duration.value + extraSeconds;
          return {
            distance: leg.distance.text,
            duration: leg.duration.text,
            adjustedDuration: formatDuration(adjustedDurationValue),
            // Use the departure time from the starting tracking item for this segment.
            time: data[index + 1].tanggal,
            startLocation: leg.start_location,
            endLocation: leg.end_location,
            steps: leg.steps,
          };
        });
        setTotalDistance(totalDist);
        setLegsInfo(legs);
      } else {
        console.error("Error fetching directions", result);
      }
    }
  );
  }, []);  

  // Compute the midpoint along the actual route of a leg
  // This requires the google.maps.geometry library to be loaded.
  const getLegMidpointOnRoute = (leg) => {
    // Combine all steps into one path
    const path = [];
    leg.steps.forEach((step) => {
      // Decode each step's polyline points
      const points = window.google.maps.geometry.encoding.decodePath(
        step.polyline.points
      );
      points.forEach((pt) => path.push(pt));
    });
    if (path.length === 0) return null;
    // Compute cumulative distances along the path
    let totalDistance = 0;
    const cumulativeDistances = [0];
    for (let i = 1; i < path.length; i += 1) {
      const segmentDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
        path[i - 1],
        path[i]
      );
      totalDistance += segmentDistance;
      cumulativeDistances.push(totalDistance);
    }
    // Find the point where the cumulative distance is half of the total
    const halfDistance = totalDistance / 2;
    for (let i = 1; i < cumulativeDistances.length; i += 1) {
      if (cumulativeDistances[i] >= halfDistance) {
        const prevDistance = cumulativeDistances[i - 1];
        const segmentLength = cumulativeDistances[i] - prevDistance;
        const overshoot = cumulativeDistances[i] - halfDistance;
        const fraction = 1 - overshoot / segmentLength;
        // Interpolate between the two points
        const midpoint = window.google.maps.geometry.spherical.interpolate(
          path[i - 1],
          path[i],
          fraction
        );
        return midpoint;
      }
    }
    return path[path.length - 1];
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const remainder = seconds % 60;
    const roundedMins = remainder >= 30 ? mins + 1 : mins;
    return `${roundedMins} mins`;
  };   

  // Fetch tracking data only on submit
  const fetchTrackingData = useCallback(async () => {
    if (!date) return;
    setLoading(true);
    try {
      const response = await axiosPrivate.post("/penagihan/get-by-petugas", {
        date: format(date, "yyyy-MM-dd"),
        petugas,
      });
      // Filter out items with null koordinat.lat or koordinat.lng,
      // then sort data by timestamp ascending
      const sortedData = response.data.length
        ? [...response.data]
            .filter(
              (item) =>
                item.koordinat &&
                item.koordinat.lat != null &&
                item.koordinat.lng != null
            )
            .sort(
              (a, b) =>
                new Date(a.tanggal).getTime() - new Date(b.tanggal).getTime()
            )
        : [];
  
      setTrackingData(sortedData);
      // After setting tracking data, calculate directions if there are at least two valid items
      if (sortedData.length >= 2) {
        calculateRoute(sortedData);
      } else {
        setDirections(null);
      }
    } catch (error) {
      console.error("Error fetching tracking data:", error);
    } finally {
      setLoading(false);
    }
  }, [date, petugas, calculateRoute]);

  return (
    <>
      <Helmet>
        <title> Tracking Maps | SIP App </title>
      </Helmet>

      <Container>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Tracking Maps by Petugas
        </Typography>

        {/* Filters */}
        <Stack direction="row" spacing={2} sx={{ mb: 3, alignItems: "center" }}>
          <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Tanggal"
              value={date}
              onChange={setDate}
              slotProps={{ textField: { size: "small", sx: { height: 40 } } }}
            />
          </LocalizationProvider>

          <Autocomplete
            options={petugasList}
            getOptionLabel={(option) => option.nama}
            value={petugasList.find((p) => p.id === petugas) || null}
            onChange={(_, newValue) => setPetugas(newValue ? newValue.id : "")}
            sx={{ width: 240, height: 40 }}
            renderInput={(params) => (
              <TextField {...params} label="Petugas" size="small" />
            )}
          />

          <Button
            variant="contained"
            onClick={fetchTrackingData}
            disabled={loading}
            sx={{ height: 40, minWidth: 100 }}
          >
            Submit
          </Button>
        </Stack>

        {/* Google Maps with Directions */}
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={12}
            center={trackingData.length ? trackingData[0].koordinat : defaultCenter}
            onLoad={(map) => { mapRef.current = map; }}
          >
            {/* Render markers at each tracking point */}
            {trackingData.map((item, index) => (
              <div key={index}>
                <Marker 
                  position={item.koordinat}
                  icon={{
                    url: "/assets/map/officer.png", // URL or import your custom icon here
                    scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
                    anchor: new window.google.maps.Point(20, 40) // Anchor the image properly
                  }}
                />

                <CustomLabel
                  koordinat={item.koordinat}
                  label={`${
                    item.dtd 
                      ? `${item.rumpun?.nama || "Unknown"} - ${item.anggota?.nama || "Unknown"}`
                      : (item.rumpun?.nama || "Unknown")
                  } - ${formatInTimeZone(new Date(item.tanggal), 'UTC', 'HH:mm')}`}
                />
              </div>
            ))}

            {/* Render midpoints on the route for each leg */}
            {legsInfo.map((leg, index) => {
              const midpoint = getLegMidpointOnRoute(leg);
              if (!midpoint) return null;

              const startLetter = String.fromCharCode(65 + index); // 65 is 'A'
              const endLetter = String.fromCharCode(65 + index + 1);
              return (
                <OverlayView
                  key={`midpoint-${index}`}
                  position={midpoint}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    style={{
                      position: 'absolute',
                      background: "#fff",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
                      whiteSpace: "nowrap",
                      fontSize: "10px",
                      fontWeight: "bold",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {`${startLetter} - ${endLetter}, ${leg.distance}`}
                  </div>
                </OverlayView>
              );
            })}

            {/* Render the computed route */}
            {directions && <DirectionsRenderer directions={directions} />}
          </GoogleMap>
        </LoadScript>
      </Container>

      {/* Display route distance details */}
      {directions && (
        <Container sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>Route Distance Details</Typography>
          {/* Show start time from the first tracking item */}
          {trackingData.length > 0 && (
            <Typography variant="body1">
              Start at: {formatInTimeZone(new Date(trackingData[0].tanggal), 'UTC', 'HH:mm')}
            </Typography>
          )}
          <Typography variant="body1">
            Total Distance: {(totalDistance / 1000).toFixed(2)} km
          </Typography>
          <Typography variant="body1">
            DTD + 5 mins
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            No DTD + 20 mins
          </Typography>
          {legsInfo.map((leg, index) => {
            const startLetter = String.fromCharCode(65 + index); // "A", "B", etc.
            const endLetter = String.fromCharCode(65 + index + 1);
            return (
              <Typography key={index} variant="body2">
                {`${startLetter} - ${endLetter}: ${leg.distance} | Scheduled: ${leg.duration} | Adjusted: ${leg.adjustedDuration} (Depart at: ${formatInTimeZone(new Date(leg.time), 'UTC', 'HH:mm')})`}
              </Typography>
            );
          })}
        </Container>
      )}
    </>
  );
}
