import { Helmet } from 'react-helmet-async';
import { useState, useMemo, useContext, useRef } from 'react';
// @mui
import {
  Card,
  Container,
  Stack,
  Button,
  Box,
  Modal,
  IconButton
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { id } from "date-fns/locale";
import { endOfDay, format, parseISO } from 'date-fns';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import { TableLoader } from '../../../components/table';
import { getCurrentDate, dateDiff } from "../../../utils/formatDate";
import LapDetailAbsenPage from './LapDetailAbsenPage';
// Context
import { axiosPrivate } from '../../../common/axiosPrivate';
import AuthContext from '../../../context/authContext';

export default function CabangAbsenPage() {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedPegawai, setSelectedPegawai] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "year",
      label: "Tahun",
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "month",
      label: "Bulan",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => getMonthName(value)
      }
    },
    {
      name: "kantor",
      label: "Kantor",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "kodePegawai",
      label: "Kode",
      options: {
        filter: false,
        sort: false
      }
    },
    {
     name: "nama",
     label: "Nama",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "tglMasuk",
      label: "Lama Kerja",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => getDateDiff(format(parseISO(value), 'yyyy-MM-dd'), getCurrentDate('-'))
      }
    },
    {
      name: "hadir",
      label: "Hadir",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "center"
        }),
        customBodyRender: (value) => (
          <Label color='success'>{value}</Label>
        ),
      }
    },
    {
      name: "alpa",
      label: "Alpa",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "center"
        }),
        customBodyRender: (value) => (
          <Label color='error'>{value}</Label>
        ),
      }
    },
    {
      name: "sakit",
      label: "Sakit",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "center"
        }),
        customBodyRender: (value) => (
          <Label color='error'>{value}</Label>
        ),
      }
    },
    {
      name: "izin",
      label: "Izin",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "center"
        }),
        customBodyRender: (value) => (
          <Label color='error'>{value}</Label>
        ),
      }
    },
    {
      name: "cuti",
      label: "Cuti",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          align: "center"
        }),
        customBodyRender: (value) => (
          <Label color='error'>{value}</Label>
        ),
      }
    },
    {
      name: "kodePegawai",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value, meta) => (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleOpenModal(1, meta.rowData)}
          >
            <Iconify icon={'eva:eye-fill'} sx={{ marginRight: '4px' }} />
            Detil
          </Button>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `Absen-${user?.kantorName}-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      }
    },
  };

  // Function to convert month number to month name
  function getMonthName(monthNumber) {
    const months = [
      "Januari", "Februari", "Maret", "April", "Mei", "Juni",
      "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
    return months[monthNumber - 1] || "";
  }

  const getDateDiff = (startDate, endDate) => {
    const { years, months, days } = dateDiff(startDate, endDate);

    let result = '';
    if (years > 0) {
        result += `${years} Tahun `;
    }
    if (months > 0) {
        result += `${months} Bulan `;
    }
    if (days > 0) {
        result += `${days} Hari `;
    }

    return result.trim(); // Remove trailing space
  };

  const handleOpenModal = (content, rowData) => {
    if(rowData) {
      // console.log(rowData);
      setSelectedPegawai({
        kodePegawai: rowData[4],
        nama: rowData[5],
        startDate: startDateRef.current,
        endDate: endDateRef.current
      });
    };
    
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleStartDateChange = (date) => {
    startDateRef.current = date;
  };

  const handleEndDateChange = (date) => {
    endDateRef.current = date;
  };

  const filterData = () => {
    setTableLoading(true);

    const modEndDate = endOfDay(endDateRef.current);

    axiosPrivate.post(`/laporan/history-absen-pegawai`, {startDate: startDateRef.current, endDate: modEndDate})
    .then((response) => {
      const data = response?.data;
      
      setData(data);
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false));
  }

  return (
    <>
      <Helmet>
        <title> Rekap Absensi Pegawai | Sip App </title>
      </Helmet>
      <Container
        sx={{
          mt: 5
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="start">
            <Box sx={{ marginRight: 2 }}>
              <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Dari Tanggal"
                  value={startDateRef.current}
                  onChange={handleStartDateChange}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Box>

            <Box sx={{ marginRight: 2 }}>
              <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Sampai Tanggal"
                  value={endDateRef.current}
                  onChange={handleEndDateChange}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Box>

            <Button variant="contained" onClick={filterData}>
              Filter
            </Button>
          </Stack>
        </Box>
        <Card>
          <MUIDataTable
            title="Rekap Absensi Pegawai"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalOpen}
        onClose={handleCloseModal}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <LapDetailAbsenPage absen={selectedPegawai} />
        </>
      </Modal>
    </>
  );
}
