import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { sub, parseISO, isValid, addWeeks, isPast, isWithinInterval, startOfDay } from 'date-fns';
import { noCase } from 'change-case';
import { useState, useEffect, useContext, useCallback } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

import { axiosPrivate } from '../../../common/axiosPrivate';

import AuthContext from '../../../context/authContext';

// ----------------------------------------------------------------------

// const NOTIFICATIONS = [
//   {
//     id: faker.datatype.uuid(),
//     title: 'Your order is placed',
//     description: 'waiting for shipping',
//     avatar: null,
//     type: 'order_placed',
//     createdAt: set(new Date(), { hours: 10, minutes: 30 }),
//     isUnRead: true,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: faker.name.fullName(),
//     description: 'answered to your comment on the Minimal',
//     avatar: '/assets/images/avatars/avatar_2.jpg',
//     type: 'friend_interactive',
//     createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
//     isUnRead: true,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'You have new message',
//     description: '5 unread messages',
//     avatar: null,
//     type: 'chat_message',
//     createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'You have new mail',
//     description: 'sent from Guido Padberg',
//     avatar: null,
//     type: 'mail',
//     createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'Delivery processing',
//     description: 'Your order is being shipped',
//     avatar: null,
//     type: 'order_shipped',
//     createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
// ];

export default function NotificationsPopover() {
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);

  const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);

  const { user } = useContext(AuthContext);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    try {
      const updatedNotifications = notifications.map(notification => ({
        ...notification,
        isUnRead: false,
      }));
  
      await axiosPrivate.patch(`notifications/mark-all-read/${user?._id}`); // Example API call to mark all notifications as read
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
    }
  };
  
  const markNotificationAsRead = async (id) => {
    try {
      await axiosPrivate.patch(`notifications/${id}/mark-as-read`); // API to mark a single notification as read
      const updatedNotifications = notifications.map(notification =>
        notification._id === id ? { ...notification, isUnRead: false } : notification
      );
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const generateContractExpirationSummary = useCallback((data) => {
    let expiredCount = 0;
    let expiringSoonCount = 0;
  
    data.forEach(data => {
      // Skip processing if tglAkhirKontrak is not defined
      if (!data.tglAkhirKontrak) {
        // console.log('Skipping undefined contract end date for data:', data);
        return;
      }

      try {
        const contractEndDate = startOfDay(parseISO(data.tglAkhirKontrak));
        if (!isValid(contractEndDate)) {
          throw new Error(`Invalid date format for contract end date: ${data.tglAkhirKontrak}`);
        }
  
        const today = startOfDay(new Date());
  
        // Check if the contract has already expired
        if (isPast(contractEndDate)) {
          expiredCount += 1;
        }
  
        // Check if the contract expires within the next two weeks
        if (isWithinInterval(contractEndDate, { start: today, end: addWeeks(today, 2) })) {
          // console.log(contractEndDate);
          
          expiringSoonCount += 1;
        }
  
      } catch (error) {
        console.error(error);
      }
    });
  
    // Create a single summary notification
    if (expiredCount > 0 || expiringSoonCount > 0) {
      return {
        recipient: user?._id,
        title: 'Ringkasan Kedaluwarsa Kontrak',
        description: `Anda memiliki ${expiredCount} kontrak yang telah kedaluwarsa dan ${expiringSoonCount} kontrak yang akan kedaluwarsa segera.`,
        avatar: null,
        link: '/dashboard/kepegawaian/laporan/pegawai-kontrak', // Link to a contracts summary or dashboard page
        type: 'contract_expiration_summary',
        createdAt: sub(new Date(), { days: 0, hours: 0, minutes: 0 }),
        isUnRead: true,
      };
    }
  
    return null; // Return null if there are no contracts expiring or expired
  }, [user?._id]);

  const processNotification = useCallback(async (notification) => {
    try {
      // Fetch the current user's notifications to check for duplicates
      const response = await axiosPrivate.get(`notifications/${user?._id}`);
      const existingNotifications = response.data;

      // Check if a similar notification has already been posted today
      const alreadyPosted = existingNotifications.some(notif => 
        notif.type === 'contract_expiration_summary' &&
        new Date(notif.createdAt).toDateString() === new Date().toDateString()
      );

      if (!alreadyPosted) {
        // Post the new notification only if no similar notification has been posted today
        await axiosPrivate.post('notifications', notification);
      }

      // Refresh the list of notifications
      const refreshedResponse = await axiosPrivate.get(`notifications/${user?._id}`);
      const refreshedNotifications = refreshedResponse.data.map(notification => ({
        ...notification,
        createdAt: new Date(notification.createdAt) // Convert string back to Date
      }));      
      
      setNotifications(refreshedNotifications);
    } catch (error) {
      console.error('Failed to process notification:', error);
    }
  }, [user?._id]);

  useEffect(() => {
    if (user.role !== 'admin') {
      // console.log('Only admins are allowed to fetch these notifications.');
      return;
    }

    const fetchData = async () => {
      // Assuming 'generateContractExpirationSummary' returns a valid notification object or null
      const response = await axiosPrivate.post('laporan/pegawai-kontrak');
      const fetchedData = response.data;
      const notification = generateContractExpirationSummary(fetchedData);
      if (notification) {
        processNotification(notification);
      }
    };
    fetchData();
  }, [generateContractExpirationSummary, processNotification, user]);

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                onMarkAsRead={markNotificationAsRead}
                // setPopoVerOpen={setOpen}
              />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem
                key={notification._id}
                notification={notification}
                onMarkAsRead={markNotificationAsRead}
                // setPopoVerOpen={setOpen}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => navigate('/notifications')}
          >
            View All
          </Button>
        </Box>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, onMarkAsRead }) {
  const { avatar, title } = renderContent(notification);

  const navigate = useNavigate();

  const handleClick = () => {
    onMarkAsRead(notification._id); // Call the function passed via props to mark as read
    navigate(notification.link); // Navigate to the link after marking as read
    // setPopoVerOpen(false);
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={handleClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  if (notification.type === 'contract_expiration_summary') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_contract_expiration.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
