import React from 'react';
import { Grid } from '@mui/material';
import { InputField } from '../../../../components/form-fields';

export default function AkunForm(props) {
  const {
    formField: {
      noRekening,
      noHp,
      noDarurat,
      nik,
      npwp,
      bpjs,
      email
    }
  } = props;
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField name={noRekening.name} label={noRekening.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={nik.name} label={nik.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={noHp.name} label={noHp.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={noDarurat.name} label={noDarurat.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={npwp.name} label={npwp.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={bpjs.name} label={bpjs.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={email.name} label={email.label} fullWidth />
        </Grid>
      </Grid>
    </>
  );
}
