import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Absensi Karyawan',
    path: '/cabang/absen',
    icon:  <Iconify icon={'ant-design:pushpin-filled'} />,
  },
  // {
  //   title: 'Absensi Rumpun',
  //   path: '/cabang/absen-rumpun',
  //   icon:  <Iconify icon={'ant-design:environment-filled'} />,
  // },
  {
    title: 'Request Cuti',
    path: '/cabang/req-cuti',
    icon:  <Iconify icon={'ant-design:carry-out-filled'} />,
  },
  {
    title: 'Laporan',
    icon:  <Iconify icon={'ant-design:file-text-filled'} />,
    submenu: [
      {
        title: 'Rekap Absensi',
        path: '/cabang/laporan/history-absen-pegawai',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'History Cuti',
        path: '/cabang/laporan/history-cuti',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Monitoring Penagihan',
        path: '/cabang/laporan/monitoring-penagihan',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
    ]
  },
];

export default navConfig;
