import { Helmet } from 'react-helmet-async';
import { useState, useMemo, useRef } from 'react';
// @mui
import {
  Card,
  Grid,
  Box,
  Button,
  Modal,
  Container,
  Stack
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id } from "date-fns/locale";
import { endOfDay, format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import { getCurrentDate, customDate } from "../../../utils/formatDate";
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import ImageComponent from '../../../components/files/image';
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function LapHistoryAbsenPage() {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [data, setData] = useState([]);
  const [modalImageOpen, setModalImageOpen] = useState(false);
  const [imageObjectKey, setImageObjectKey] = useState(null)
  const [tableLoading, setTableLoading] = useState(false);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "tanggal",
      label: "Tanggal",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => Object.prototype.toString.call(value) ? format(parseISO(value), 'dd/MM/yyyy') : format(new Date(value), 'dd/MM/yyyy'),
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return [`Dari: ${startDate}`, `Sampai: ${enDate}`];
            } if (v[0]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');

              return `Dari: ${startDate}`;
            } if (v[1]) {
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return `Sampai: ${enDate}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          names: [],
          logic(date, filters) {
            const formated = new Date(customDate(date));

            if (filters[0] && filters[1]) {
              return !(formated >= filters[0] && formated <= filters[1]);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Dari"
                      value={filterList[index][0] || null}
                      onChange={date => {
                        filterList[index][0] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Sampai"
                      value={filterList[index][1] || null}
                      onChange={date => {
                        filterList[index][1] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </>
          ),
        },
      }
    },
    {
      name: "jamMasuk",
      label: "Jam Masuk",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, meta) =>
          value && meta.rowData[9]?.toLowerCase() === 'hadir'
            ? format(utcToZonedTime(parseISO(value), 'UTC'), 'HH:mm', {
                timeZone: 'UTC',
              })
            : '-',
      },
    },     
    {
      name: "jamKeluar",
      label: "Jam Keluar",
      options: {
       filter: false,
       sort: true,
       customBodyRender: (value, meta) =>
          value && meta.rowData[9]?.toLowerCase() === 'hadir'
            ? format(utcToZonedTime(parseISO(value), 'UTC'), 'HH:mm', {
                timeZone: 'UTC',
              })
            : '-',
      }
    },
    {
     name: "pegawai",
     label: "Nama",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "kode",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "kantor",
      label: "Kantor",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "jenis",
      label: "Absensi",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <Label color={getColorByAbsensi(value)}>{value}</Label>
      }
    },
    {
      name: "keterangan",
      label: "Keterangan",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "image",
      label: "Image",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "_id",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value, meta) => (
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleOpenModalImage(meta.rowData)}
            disabled={meta.rowData[11] === undefined || meta.rowData[11] === null || meta.rowData[11] === ""}
          >
            <Iconify icon={'eva:image-fill'} sx={{ marginRight: '4px' }} />
            Foto
          </Button>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `laporan-history-absen-pegawai-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const getColorByAbsensi = (absensiValue) => {
    const colorMap = {
      Alpa: 'error',
      Sakit: 'error',
      Izin: 'error',
      Cuti: 'error',
      // Add more mappings as needed
    };
  
    return colorMap[absensiValue] || 'success';
  };

  const handleCloseModalImage = () => {
    setModalImageOpen(false);
  }

  const handleOpenModalImage = (rowData) => {
    setModalImageOpen(true);

    axiosPrivate.post(`pegawai-absen/absen/get-image`, {
      kodePegawai: rowData[6],
      tanggal: rowData[2]
    })
    .then((response) => {
      const imageUrl = response?.data?.image;

      if(!imageUrl) {
        setImageObjectKey('');
      } else {
        setImageObjectKey(imageUrl);
      }
    })
    .catch((error) => {
      console.log(error);
      setImageObjectKey('');
    });
  }

  const handleStartDateChange = (date) => {
    startDateRef.current = date;
  };

  const handleEndDateChange = (date) => {
    endDateRef.current = date;
  };

  const filterData = () => {
    setTableLoading(true);

    const modEndDate = endOfDay(endDateRef.current);

    axiosPrivate.post(`pegawai-absen/kantor`, {startDate: startDateRef.current, endDate: modEndDate})
    .then((response) => {
      const data = response?.data;

      // console.log(data);
      
      setData(data);
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false));
  }

  return (
    <>
      <Helmet>
        <title> Absensi Pegawai | Sip App </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="start" sx={{ mb: 2 }}>
          <Box sx={{ marginRight: 2 }}>
            <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Dari Tanggal"
                value={startDateRef.current}
                onChange={handleStartDateChange}
                slotProps={{ textField: { size: 'small' } }}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ marginRight: 2 }}>
            <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Sampai Tanggal"
                value={endDateRef.current}
                onChange={handleEndDateChange}
                slotProps={{ textField: { size: 'small' } }}
              />
            </LocalizationProvider>
          </Box>

          <Button variant="contained" onClick={filterData}>
            Filter
          </Button>
        </Stack>

        <Card>
          <MUIDataTable
            title="Absensi Pegawai"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        open={modalImageOpen}
        onClose={handleCloseModalImage}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}>
          <ImageComponent
            objectKey={imageObjectKey}
            onClose={handleCloseModalImage}
          />
        </Box>
      </Modal>
    </>
  );
}
