import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { Container, Typography, Stack, Box, Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import AkunForm from '../../../sections/@dashboard/pegawai/forms/AkunForm';
import pegawaiFormModel from '../../../sections/@dashboard/pegawai/form-model/pegawaiFormModel';
import validationSchema from '../../../sections/@dashboard/pegawai/form-model/validationSchema';

const AkunPegawaiPage = ({id, handleCloseModal, handleUpdateTable}) => {
  const notificationCtx = useContext(NotificationContext);
  const { user } = useContext(AuthContext);
  const [pageLoading, setPageLoading] = useState(true);
  const { formId, formField } = pegawaiFormModel;
  const [initialValues, setInitialValues] = useState({});

  const _handleSubmit = async (values, actions) => {
    const params = {
      noRekening: values?.noRekening,
      noHp: values?.noHp,
      noDarurat: values?.noDarurat,
      nik: values?.nik,
      npwp: values?.npwp,
      bpjs: values?.bpjs,
      email: values?.email,
      dibuatOleh: user?._id,
      tglUbah: moment().format('YYYY-MM-DDTHH:mm:ss')
    };

    // console.log(JSON.stringify(params));

    await axiosPrivate.put(`/pegawai/update-partial/${id}`, params)
    .then((response) => {
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);
    })
    .catch((error) => {
      console.log(error);
      handleMessage(error)
    })
    .finally(() => {
      actions.setSubmitting(false);
    });
  }

  const handleMessage = (params) => {
    if(params?.success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: 'Data Berhasil Diubah.'
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.response
      })
    }
  }

  useEffect(() => {
    axiosPrivate.get(`/pegawai/${id}`)
    .then((response) => {
      const data = response?.data;

      setInitialValues({
        noRekening: data?.noRekening,
        noHp: data?.noHp,
        noDarurat: data?.noDarurat,
        nik: data?.nik,
        npwp: data?.npwp,
        bpjs: data?.bpjs,
        email: data?.email,
      });
    })
    .catch((error) => console.log(error?.response))
    .finally(() => setPageLoading(false));
  }, [id]);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Ubah Akun
            </Typography>
          </Stack>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema[1]}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                <AkunForm formField={formField} />
                <Stack
                  direction="row"
                  justifyContent="end"
                  mt={2}
                  spacing={1}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseModal}
                  >
                    Kembali
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                  >
                    Simpan
                  </LoadingButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Container>
        )}
    </>
  );
};
  
export default AkunPegawaiPage;
