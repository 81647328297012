import { Helmet } from 'react-helmet-async';
import { useState, useMemo, useContext, useRef } from 'react';
// @mui
import {
  Card,
  Grid,
  Box,
  Stack,
  Button,
  ButtonGroup,
  Modal,
  IconButton,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id } from "date-fns/locale";
import { endOfDay, format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import MUIDataTable from "mui-datatables";
import CloseIcon from "@mui/icons-material/Close";
import { TableLoader } from '../../../components/table';
import { getCurrentDate, customDate } from "../../../utils/formatDate";
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import CabangAddAbsenPage from './CabangAddAbsenPage';
import CabangEditAbsenPage from './CabangEditAbsenPage';
import ImageComponent from '../../../components/files/image';
import { axiosPrivate } from '../../../common/axiosPrivate';
import AuthContext from '../../../context/authContext';
import NotificationContext from '../../../context/notificationContext';

export default function CabangAbsenPage() {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const notificationCtx = useContext(NotificationContext);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [modalImageOpen, setModalImageOpen] = useState(false);
  const [imageObjectKey, setImageObjectKey] = useState(null)
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [modalContent, setModalContent] = useState();
  const [modalFormOpen, setModalFormOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "tanggal",
      label: "Tanggal",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => Object.prototype.toString.call(value) ? format(parseISO(value), 'dd/MM/yyyy') : format(new Date(value), 'dd/MM/yyyy'),
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return [`Dari: ${startDate}`, `Sampai: ${enDate}`];
            } if (v[0]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');

              return `Dari: ${startDate}`;
            } if (v[1]) {
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return `Sampai: ${enDate}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          names: [],
          logic(date, filters) {
            const formated = new Date(customDate(date));

            if (filters[0] && filters[1]) {
              return !(formated >= filters[0] && formated <= filters[1]);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Dari"
                      value={filterList[index][0] || null}
                      onChange={date => {
                        filterList[index][0] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Sampai"
                      value={filterList[index][1] || null}
                      onChange={date => {
                        filterList[index][1] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </>
          ),
        },
      }
    },
    {
      name: "jamMasuk",
      label: "Jam Masuk",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, meta) =>
          value && meta.rowData[8]?.toLowerCase() === 'hadir'
            ? format(utcToZonedTime(parseISO(value), 'UTC'), 'HH:mm', {
                timeZone: 'UTC',
              })
            : '-',
      },
    },     
    {
      name: "jamKeluar",
      label: "Jam Keluar",
      options: {
       filter: false,
       sort: true,
       customBodyRender: (value, meta) =>
          value && meta.rowData[8]?.toLowerCase() === 'hadir'
            ? format(utcToZonedTime(parseISO(value), 'UTC'), 'HH:mm', {
                timeZone: 'UTC',
              })
            : '-',
      }
    },
    {
     name: "pegawai",
     label: "Nama",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "kode",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "jenis",
      label: "Absensi",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => <Label color={getColorByAbsensi(value)}>{value}</Label>
      }
    },
    {
      name: "keterangan",
      label: "Keterangan",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "kantor",
      label: "Kantor",
      options: {
        filter: true,
        sort: true,
        display: user?.kantor === '9999'
      }
    },
    {
      name: "image",
      label: "Image Path",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "_id",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customHeadRender: () => null,
        customBodyRender: (value, meta) => (
          <ButtonGroup>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleOpenModalImage(meta.rowData)}
              disabled={meta.rowData[11] === undefined || meta.rowData[11] === null || meta.rowData[11] === ""}
            >
              <Iconify icon={'eva:image-fill'} />
            </Button>
            <Button
              variant="outlined"
              size="small"
              disabled={meta.rowData[9] === undefined || meta.rowData[9] === null || meta.rowData[9] === ""}
              onClick={() => handleOpenModalForm(1, value)}
            >
              <Iconify icon={'eva:edit-fill'} />
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleClickOpenDialog(value)}
              disabled={meta.rowData[9] === undefined && meta.rowData[8] === 'Hadir'}
            >
              <Iconify icon={'eva:trash-2-fill'} />
            </Button>
          </ButtonGroup>
        )
      }
    }
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `laporan-history-absen-pegawai-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const getColorByAbsensi = (absensiValue) => {
    const colorMap = {
      Alpa: 'error',
      Sakit: 'error',
      Izin: 'error',
      Cuti: 'error',
      // Add more mappings as needed
    };
  
    return colorMap[absensiValue] || 'success';
  };

  const handleCloseModalImage = () => {
    setModalImageOpen(false);
  }

  const isValidBase64Image = (imageUrl) => {
    // Regular expression for validating base64 format
    const base64Regex = /^\s*data:(image\/(png|jpeg|gif));base64,/;
  
    // Check if the imageUrl matches the pattern
    return base64Regex.test(imageUrl);
  };

  const handleOpenModalImage = (rowData) => {
    setModalImageOpen(true);
    
    axiosPrivate.post(`pegawai-absen/absen/get-image`, {
      kodePegawai: rowData[6],
      tanggal: rowData[2]
    })
    .then((response) => {
      const imageUrl = response?.data?.image;

      if(!imageUrl) {
        setImageObjectKey('');
      } else if (isValidBase64Image(imageUrl)) {
        setImageObjectKey(imageUrl);
      } else {
        // const cacheBuster = new Date().getTime();
        setImageObjectKey(imageUrl);
      }
    })
    .catch((error) => {
      console.log(error);
      setImageObjectKey('');
    });
  }

  const handleOpenModalForm = (content, id) => {
    setModalFormOpen(true);
    setSelectedId(id);
    setModalContent(content);
  }

  const handleClickOpenDialog = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseModalForm = () => {
    setModalFormOpen(false);
  }

  const handleStartDateChange = (date) => {
    startDateRef.current = date;
  };
  
  const handleEndDateChange = (date) => {
    endDateRef.current = date;
  };

  const handleUpdateTable = (newDataArray) => {
    // Process each item in the array
    setData(currentData => {
      // Start with the current state
      let updatedData = [...currentData];
  
      newDataArray.forEach(newData => {
        const index = updatedData.findIndex(item => item._id === newData._id);
        if (index !== -1) {
          // If the item exists, update it
          updatedData[index] = { ...updatedData[index], ...newData };
        } else {
          // If the item does not exist, add it
          updatedData = [newData, ...updatedData];
        }
      });
  
      // Return the updated state
      return updatedData;
    });
  };
  
  const handleDeleteData = () => {
    axiosPrivate.get(`/pegawai-absen/pegawai/destroy/${selectedId}`)
    .then((response) => {
      const data = response?.data;

      setData(currentItems => currentItems.filter(item => item._id !== selectedId));
      handleMessage(data);
    })
    .catch((error) => handleMessage(error))
    .finally(handleCloseDialog);
  }

  const handleMessage = (params) => {
    const success = params?.success;

    if (success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: params?.message
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.message
      });
    }
  };

  const filterData = () => {
    setTableLoading(true);

    const { kantor } = user;
    const modEndDate = endOfDay(endDateRef.current);

    axiosPrivate.post(`pegawai-absen/kantor`, {kantor, startDate: startDateRef.current, endDate: modEndDate})
    .then((response) => {
      const data = response?.data;

      // console.log(data);

      setData(data);
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false));
  }

  return (
    <>
      <Helmet>
        <title> Absensi Karyawan | Sip App </title>
      </Helmet>

      <Container>
        {/* Date Filters */}
        <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Dari Tanggal"
              value={startDateRef.current}
              onChange={handleStartDateChange}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>

          <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Sampai Tanggal"
              value={endDateRef.current}
              onChange={handleEndDateChange}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>

          <Button variant="contained" onClick={filterData}>
            Filter
          </Button>
        </Stack>

        {/* Add Button */}
        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ mb: 2 }}>
          <Button
            onClick={() => handleOpenModalForm(0)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Tambah
          </Button>
        </Stack>

        {/* Data Table */}
        <Card>
          <MUIDataTable
            title="List Absensi Karyawan"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        hideBackdrop
        open={modalFormOpen}
        onClose={handleCloseModalForm}
        style={{ backgroundColor: "#fff", backdropFilter: "blur(0px)" }}
      >
        <>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              margin: 1
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModalForm}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {modalContent === 0 ? (
            <CabangAddAbsenPage
              handleCloseModal={handleCloseModalForm}
              handleUpdateTable={handleUpdateTable}
            />
          ) : (
            <CabangEditAbsenPage
              id={selectedId}
              handleCloseModal={handleCloseModalForm}
              handleUpdateTable={handleUpdateTable}
            />
          )}
        </>
      </Modal>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        open={modalImageOpen}
        onClose={handleCloseModalImage}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}>
          <ImageComponent
            objectKey={imageObjectKey}
            onClose={handleCloseModalImage}
          />
        </Box>
      </Modal>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Perhatian!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin menghapus data ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Tidak</Button>
          <Button onClick={handleDeleteData} autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
