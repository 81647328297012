// component
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'master',
    icon: <PostAddRoundedIcon sx={{ width: 1, height: 1 }} />,
    submenu: [
      {
        title: 'Kantor',
        path: '/dashboard/master/kantor',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Jabatan',
        path: '/dashboard/master/jabatan',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Kota/kabupaten',
        path: '/dashboard/master/kota-kabupaten',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Kecamatan',
        path: '/dashboard/master/kecamatan',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      // {
      //   title: 'gaji',
      //   path: '/dashboard/master/gaji',
      //   icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      // },
    ]
  },
  {
    title: 'Pegawai',
    icon: <AssignmentIndRoundedIcon sx={{ width: 1, height: 1 }} />,
    submenu: [
      {
        title: 'Aktif',
        path: '/dashboard/kepegawaian/pegawai',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Masuk',
        path: '/dashboard/kepegawaian/pegawai-masuk',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Keluar',
        path: '/dashboard/kepegawaian/pegawai-keluar',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
    ]
  },
  {
    title: 'Promosi',
    path: '/dashboard/kepegawaian/promosi',
    icon:  <Iconify icon={'ant-design:notification-filled'} />,
  },
  {
    title: 'Mutasi',
    path: '/dashboard/kepegawaian/mutasi',
    icon:  <Iconify icon={'ant-design:interaction-filled'} />,
  },
  {
    title: 'Dokumen',
    path: '/dashboard/kepegawaian/dokumen',
    icon:  <Iconify icon={'ant-design:file-pdf-filled'} />,
  },
  {
    title: 'Absensi',
    path: '/dashboard/kepegawaian/absen',
    icon:  <Iconify icon={'ant-design:pushpin-filled'} />,
  },
  {
    title: 'Birthday',
    path: '/dashboard/kepegawaian/birthday',
    icon:  <Iconify icon={'ant-design:calendar-filled'} />,
  },
  {
    title: 'Cuti',
    icon:  <Iconify icon={'ant-design:schedule-filled'} />,
    submenu: [
      {
        title: 'Saldo Cuti',
        path: '/dashboard/kepegawaian/cuti/saldo-cuti',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Cuti Request',
        path: '/dashboard/kepegawaian/cuti/cuti-request',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Proses Cuti',
        path: '/dashboard/kepegawaian/cuti/process-cuti',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
    ]
  },
  {
    title: 'Penagihan',
    icon:  <Iconify icon={'ant-design:environment-filled'} />,
    submenu: [
      {
        title: 'Monitoring',
        path: '/dashboard/kepegawaian/penagihan/monitoring',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Tracking',
        path: '/dashboard/kepegawaian/penagihan/tracking',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
    ]
  },
  {
    title: 'Laporan',
    icon:  <Iconify icon={'ant-design:file-text-filled'} />,
    submenu: [
      {
        title: 'Promosi & Mutasi',
        path: '/dashboard/kepegawaian/laporan/promosi-mutasi',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Kinerja',
        path: '/dashboard/kepegawaian/laporan/kinerja',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Kinerja Kantor',
        path: '/dashboard/kepegawaian/laporan/kinerja-kantor',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Pegawai Kontrak',
        path: '/dashboard/kepegawaian/laporan/pegawai-kontrak',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Kehadiran Absen',
        path: '/dashboard/kepegawaian/laporan/absen-pegawai',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'Rekap Absen',
        path: '/dashboard/kepegawaian/laporan/history-absen-pegawai',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
      {
        title: 'History Cuti',
        path: '/dashboard/kepegawaian/laporan/history-cuti',
        icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
      },
    ]
  },
  {
    title: 'User',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'Manajemen Pengguna',
  //   icon: icon('ic_user'),
  //   submenu: [
  //     {
  //       title: 'User',
  //       path: '/dashboard/user',
  //       icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
  //     },
  //     {
  //       title: 'Roles',
  //       path: '/dashboard/master/jabatan',
  //       icon: <FiberManualRecordIcon sx={{ fontSize: 6 }} />,
  //     },
  //   ]
  // }
];

export default navConfig;
