import { Helmet } from 'react-helmet-async';
import { useState, useMemo, useContext, useRef } from 'react';
// @mui
import {
  Card,
  Container,
  Stack,
  Button,
  Box,
  Modal
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { id } from "date-fns/locale";
import { endOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import Iconify from '../../../components/iconify';
import ImageComponent from '../../../components/files/image';
import { TableLoader } from '../../../components/table';
import { getCurrentDate } from "../../../utils/formatDate";
// Context
import { axiosPrivate } from '../../../common/axiosPrivate';
import AuthContext from '../../../context/authContext';

export default function MonitoringPenagihanPage() {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalImageOpen, setModalImageOpen] = useState(false);
  const [imageObjectKey, setImageObjectKey] = useState(null);

  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
      name: "rumpun",
      label: "Rumpun",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => value?.nama
      }
    },
    {
      name: "anggota",
      label: "Anggota",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => value?.nama
      }
    },
    {
      name: "petugas",
      label: "Petugas",
      options: {
       filter: true,
       sort: true,
       customBodyRender: (value) => value?.nama
      }
    },
    {
      name: "tanggal",
      label: "Tanggal",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => formatInTimeZone(new Date(value), 'UTC', 'dd/MM/yyyy HH:mm')
      }
    },
    {
      name: "dtd",
      label: "DTD",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value ? 'Ya' : 'Tidak'
      }
    },
    {
      name: "tertagih",
      label: "Tertagih",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value ? 'Ya' : 'Tidak'
      }
    },
    {
      name: "koordinat",
      label: "Koordinat",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value) => `${value?.lat}, ${value?.lng}`
      }
    },
    {
      name: "foto",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = tableMeta?.rowData;
          const koordinat = rowData.find((col) => col?.lat && col?.lng);
          const foto = value; // Assuming the value is the image URL
    
          const mapLink = koordinat
            ? `https://www.google.com/maps?q=${koordinat.lat},${koordinat.lng}`
            : null;
    
          return (
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              {/* Map Button */}
              {mapLink ? (
                <a href={mapLink} target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined" size="small">
                    <Iconify icon={"eva:pin-outline"} sx={{ marginRight: "4px" }} />
                    Map
                  </Button>
                </a>
              ) : (
                <Button variant="outlined" size="small" disabled>
                  <Iconify icon={"eva:pin-outline"} sx={{ marginRight: "4px" }} />
                  No Map
                </Button>
              )}
    
              {/* Foto Button */}
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleOpenModalImage(foto)}
                disabled={!foto}
              >
                <Iconify icon={"eva:image-fill"} sx={{ marginRight: "4px" }} />
                Foto
              </Button>
            </div>
          );
        },
      },
    }    
  ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `Absen-${user?.kantorName}-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      }
    },
  };

  const handleOpenModalImage = (imageUrl) => {
    setImageObjectKey(imageUrl);
    setModalImageOpen(true);
  }

  const handleCloseModalImage = () => {
    setModalImageOpen(false);
  }
  
  const handleStartDateChange = (date) => {
    startDateRef.current = date;
  };

  const handleEndDateChange = (date) => {
    endDateRef.current = date;
  };

  const filterData = () => {
    setTableLoading(true);

    const { kantor } = user;
    const modEndDate = endOfDay(endDateRef.current);

    axiosPrivate.post(`/laporan/monitoring-penagihan`, {kantor, startDate: startDateRef.current, endDate: modEndDate})
    .then((response) => {
      const data = response?.data;

      setData(data);
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false));
  }

  return (
    <>
      <Helmet>
        <title> Monitoring Penagihan | Sip App </title>
      </Helmet>
      <Container
        sx={{
          mt: 5
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="start">
            <Box sx={{ marginRight: 2 }}>
              <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Dari Tanggal"
                  value={startDateRef.current}
                  onChange={handleStartDateChange}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Box>

            <Box sx={{ marginRight: 2 }}>
              <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Sampai Tanggal"
                  value={endDateRef.current}
                  onChange={handleEndDateChange}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Box>

            <Button variant="contained" onClick={filterData}>
              Filter
            </Button>
          </Stack>
        </Box>
        <Card>
          <MUIDataTable
            title="Monitoring Penagihan"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>

      <Modal
        sx={{
          overflowY: 'scroll',
          pt: 5,
          pb: 5
        }}
        open={modalImageOpen}
        onClose={handleCloseModalImage}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}>
          <ImageComponent
            objectKey={imageObjectKey}
            onClose={handleCloseModalImage}
          />
        </Box>
      </Modal>
    </>
  );
}
